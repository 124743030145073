<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <template>
            <v-row style="background-color:#ff9f1c;" class="pa-8 q_white-text q-title-bar-title">Quility Shop</v-row>
            <v-row class="ma-5">
                <div style="width:1400px; max-width:90%; margin:0 auto;">
                    <v-card class="pa-5 mb-5">
                        <q-shop-codes></q-shop-codes>
                        <div class="mb-5 pa-6">
                            <v-divider class="py-5" />
                            <v-row class="pb-8 q-title-bar-title">Create Your Quility Shop Account</v-row>
                            <v-row>
                                <h4>Step 1: Sign Up for an Account</h4>
                            </v-row>
                            <v-card class="d-flex " elevation="0">
                                <div>You can visit the site using the link above, or by going directly to shopquility.com and clicking the button to Create an Account.</div>
                                <div class="pa-2 ma-0" style="max-width:50%;">
                                    <v-img :src="require('@/assets/images/quility-create-account.png')" max-height="400px" aspect-ratio="" contain class="" />
                                </div>
                            </v-card>
                        </div>
                        <div class="mb-5">
                            <h4>Step 2: Enter Your Information</h4>
                            <v-card class="d-flex " elevation="0">
                                <div>
                                    <p>Please fill out your information to create an account. Required fields are marked with an Asterisk *.</p>
                                    <ol>
                                        <li>
                                            <p>Fill out the General Information section.</p>
                                        </li>
                                        <li>
                                            <p>In the Additional Information section, please check the Verification Code field. If you followed one of the links above, your verification code will already be filled in, but if you do not see it in this field, please enter it.</p>
                                        </li>
                                        <li>
                                            <p>Continue filling out the Address Information, Password, and CAPTCHA sections.</p>
                                            <p><strong>Pro tip:</strong> We strongly suggest using the same email address for both HQ and the Quility Shop. This will allow for a smooth transition to Single Sign On which is coming soon!</p>
                                        </li>
                                        <li>
                                            <p>At the bottom of the form, click the Create Account button.</p>
                                        </li>
                                    </ol>
                                </div>
                                <div class="pa-2 ma-0" style="max-width:50%;">
                                    <v-img :src="require('@/assets/images/quility-form.png')" max-height="400px" aspect-ratio="" contain class="aligncenter" />
                                </div>
                            </v-card>
                        </div>
                        <div class="mb-5">
                            <h4>Step 3: Login</h4>
                        </div>
                        <v-divider class="pb-5" />
                        <p><strong>Once your account is ready,</strong> you will receive a confirmation email. You can log in anytime with your email address and password at shopquility.com and browse all of the available products.
                            Need Help?</p>
                        <p><strong>If you need help,</strong> you can contact us Mon-Fri 8:30AM to 5PM PT using the chat feature in the bottom right corner of the shop. We can help you if you have any questions about the site or products, have trouble logging in, or need assistance with an order.</p>
                        <p>Outside of those hours, you can also contact us by email at onlineorders@renoprintstore.com and we will respond within one business day.</p>
                        <p><strong>Forgotten Password</strong><br />
                            If you have lost or forgotten your password, you can use the “Reset Password” link below the login button to change your password.</p>
                    </v-card>
                </div>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import Page from '@/CMS/views/index.vue'
import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';
import QShopCodes from '@/components/utils/QShopCodes.vue';
export default {
    data() {
        return {}
    },
    methods: {},
    components: {
        QShopCodes
    }
}

</script>
