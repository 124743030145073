<template>
    <news-template>
        <v-container>
            <!--<q-filters v-model="filters" :data="news" :keys="filter_keys"></q-filters>//-->
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-row id="News">
                <v-col cols="12">
                    <page slug="news-header"></page>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="mb-4" v-for="(item, key) in pagedResults" :key="item.id">
                    <v-hover v-slot:default="{ hover }">
                        <v-card @click="resourceLink(item)" height="100%" class="flexcard pa-4" :elevation="hover ? 9 : 2">
                            <v-row wrap class="px-4 pt-0 pb-4">
                                <v-col cols="12">
                                    <v-img v-if="item.image" class="grow mb-4" :src="item.image" contain :width="506"></v-img>
                                    <v-list-item-title>
                                        <h3>{{item.title}}</h3>
                                    </v-list-item-title>
                                    <v-card-text>{{item.description}}</v-card-text>
                                    <v-list-item-subtitle>{{formatDateCalendar(item.created_at)}}</v-list-item-subtitle>
                                    <v-btn v-if="item.external_url" depressed fab small absolute bottom right color="q_green_4">
                                        <v-icon small light>fas fa-external-link-alt</v-icon>
                                    </v-btn>
                                </v-col>
                                <!--
                                <v-col cols="12" sm="4">
                                    <v-img class="grow" :src="item.image" :aspect-ratio="2" cover :height="169"></v-img>
                                </v-col>
                            -->
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <v-pagination v-if="pagedResults.length > 0 && !loading" v-model="page" :length="total_pages" :total-visible="7" color="q_green_1"></v-pagination>
        </v-container>
    </news-template>
</template>
<script>
import NewsPage from '@/store/Models/NewsPage'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QFilters from '@/components/utils/QFilters.vue'
import NewsTemplate from '@/CMS/views/PageTemplates/news.vue'
import Page from '@/CMS/views/index.vue'
export default {
    props: ['type'],
    data: function() {
        return {
            filters: {},
            filter_keys: [],
            itemsPerPage: 10,
            page: 1,
            pagedResults: [],
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        'total_pages': function() {
            return Math.ceil(this.filtered_data.length / this.itemsPerPage);
        },
        'all_results': function() {
            return this.filtered_data
        },
        'loading': function() {
            return this.$store.state.entities.news_page.loading
        },
        'news': function() {
            return NewsPage.query()
                .where('category_id', 15)
                .where('division', this.user.currentSetDivision)
                .where((page) => {
                    return page.status == "publish" || page.status == null
                })
                .orderBy('order')
                .get()
        },
        filtered_data: function() {
            var query = NewsPage.query().where('category_id', 15).where('status', "publish").where('division', this.user.currentSetDivision)
            for (var i = 0; i < this.filter_keys.length; i++) {
                var key = this.filter_keys[i]
                if (this.filters[key] != '-All-') {
                    query.where(key, this.filters[key])
                }
            }
            return query.orderBy('order').get()
        },
    },
    methods: {
        loadData: function(opt) {
            var d = new Date()
            var g = this;
            QuilityAPI.appendPages({ 'category_id': 15 }, { 'itemsPerPage': 'all' }, 'NewsPage').then(function() {
                g.updatePagedResults(g.page)
            });
        },
        resourceLink: function(item) {
            if (item.external_url && item.external_url != "internal") {
                if (item.external_url.indexOf('quility.com') > -1) {
                    document.location = item.external_url
                } else {
                    window.open(item.external_url, '_tab');
                }
            } else {
                this.$router.push("/page/" + item.slug)
            }
        },
        updatePagedResults(page) {
            var first = page * this.itemsPerPage - this.itemsPerPage;
            var last = this.itemsPerPage * page;
            this.pagedResults = this.all_results.slice(first, last)
        }
    },
    watch: {
        'page': function(newV) {
            this.updatePagedResults(newV)
        },
        'user.currentSetDivision': function(newV) {
            this.updatePagedResults(1)
        }
    },
    components: {
        QFilters,
        NewsTemplate,
        Page
    }
}

</script>
<style lang="scss" scoped>
.sponsor {
    text-transform: uppercase;
    font-size: .9em;
    display: inline-block;
    line-height: 1.1em;
    padding-left: 8px;
}

.flexcard {
    display: flex;
    flex-direction: column;
}

.word-wrap {
    word-break: break-word;
}

.card-image {
    max-height: 169px;
    overflow: hidden;
}

</style>
