<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <template>
            <v-row style="background-color:#360568;" class="pa-8 q_white-text q-title-bar-title">Get The Opt Mobile App</v-row>
            <v-row class="ma-5">
                <div style="width:960px; max-width:90%; margin:0 auto;">
                    <v-card class="pa-5 mb-5">
                        <div class="mb-5 pa-6">
                            <v-row>
                                <h4>Get the Leads Manager Mobile App* and use Opt! on your iPad, iPhone or Android device. </h4>
                                <p>*Available with Opt! Premium, Opt! Elite, or Opt! Virtual Manager subscriptions.</p>
                            </v-row>
                            <v-card class="d-flex mt-4" elevation="0">
                                <div>
                                    <v-btn depressed large block dark class="ma-2" color="q_leads_3" href="https://itunes.apple.com/us/app/leads-manager/id1439567874?mt=80">
                                        <v-icon right class="ma-2">fab fa-apple</v-icon>iPhone / iPad
                                    </v-btn>
                                    <v-btn depressed large block dark class="ma-2" color="q_leads_3" href="https://play.google.com/store/apps/details?id=com.optsoft.optleadsmanager">
                                        <v-icon right class="ma-2">fab fa-google-play</v-icon>Android
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                    </v-card>
                </div>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import Page from '@/CMS/views/index.vue'
import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';

export default {
    data() {
        return {}
    },
    methods: {},
    components: {}
}

</script>
