<template>
    <contest-template>
        <v-container>
            <!--<q-filters v-model="filters" :data="contests" :keys="filter_keys"></q-filters>//-->
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-row wrap id="Contests">
                <v-col cols="12">
                        <page slug="contests-header"></page>
                    </v-col>
                <v-col cols='12' sm="6" md="4" v-for="(event, key) in pagedResults" :key="event.id">
                    <v-card height="100%" class="flexcard">
                        <div v-if="event.image">
                            <v-img class="grow " :src="event.image" :aspect-ratio="1.7778" contain></v-img>
                        </div>
                        <v-card-title class="word-wrap">{{event.title}}</v-card-title>
                        <v-card-subtitle>Qualification Dates :<br><strong> {{formatDateCalendarWithYear(event.startDate)}} through {{formatDateCalendarWithYear(event.endDate)}}</strong></v-card-subtitle>
                        <v-card-text class="grow">{{event.description}}</v-card-text>
                        <v-card-actions>
                            <span class="q_green_2--text sponsor">{{event.sponsor}}</span>
                            <v-spacer></v-spacer>
                            <v-btn @click="resourceLink(event)" target="_tab" depressed color="primary">Details <v-icon right small>fas fa-external-link-alt</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-pagination v-if="pagedResults.length > 0 && !loading" v-model="page" :length="total_pages" :total-visible="7"></v-pagination>
        </v-container>
    </contest-template>
</template>
<script>
import ContestTemplate from '@/CMS/views/PageTemplates/contests.vue'
import ContestPage from '@/store/Models/ContestPage'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QFilters from '@/components/utils/QFilters.vue'
import page from '@/CMS/views/index.vue'

export default {
    props: ['type'],
    data: function() {
        return {
            filters: {},
            filter_keys: ['sponsor'],
            itemsPerPage: 12,
            page: 1,
            pagedResults: [],
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        'loading': function() {
            return this.$store.state.entities.contest_page.loading
        },
        'total_pages': function() {
            return Math.ceil(this.filtered_data.length / this.itemsPerPage);
        },
        'all_results': function() {
            return this.filtered_data
        },
        'contests': function() {
            return ContestPage.query()
                .where('category_id', 14)
                .where('division', this.user.currentSetDivision)
                .where((page) => {
                    return page.status == "publish" || page.status == null
                })
                .orderBy('order')
                .get()
        },
        filtered_data: function() {
            var query = ContestPage.query().where('category_id', 14).where('status', "publish").where('division', this.user.currentSetDivision)
            /*for (var i = 0; i < this.filter_keys.length; i++) {
                var key = this.filter_keys[i]
                if (this.filters[key] != '-All-') {
                    query.where(key, this.filters[key])
                }
            }*/
            return query.orderBy('order').get()
        },
    },
    methods: {
        loadData: function(opt) {
            var g = this;
            QuilityAPI.appendPages({ 'category_id': 14, 'itemsPerPage': 'all'}, {}, 'ContestPage').then(function() {
                g.updatePagedResults(g.page)
            });
        },
        resourceLink: function(item) {
            if (item.external_url && item.external_url != "internal") {
                if (item.external_url.indexOf('quility.com') > -1) {
                    document.location = item.external_url
                } else {
                    window.open(item.external_url, '_tab');
                }
            } else {
                this.$router.push("/page/" + item.slug)
            }
        },
        updatePagedResults(page) {
            if (typeof page == 'undefined') {
                page = this.page;
            }
            var first = page * this.itemsPerPage - this.itemsPerPage;
            var last = this.itemsPerPage * page;
            this.pagedResults = this.all_results.slice(first, last)
        }
    },
    watch: {
        'page': function(newV) {
            this.updatePagedResults(newV)
        },
        'user.currentSetDivision': function(newV) {
            this.updatePagedResults(1)
        },
        'filters': {
            deep: true,
            handler: function(newV) {
                this.page = 1
                this.$nextTick(this.updatePagedResults)
            }
        }
    },
    components: {
        QFilters,
        ContestTemplate,
        page

    }
}

</script>
<style lang="scss" scoped>
.sponsor {
    text-transform: uppercase;
    font-size: .9em;
    display: inline-block;
    line-height: 1.1em;
    padding-left: 8px;
}

.flexcard {
    display: flex;
    flex-direction: column;
}

.word-wrap {
    word-break: break-word;
}

.card-image {
    max-height: 169px;
    overflow: hidden;
}

</style>
